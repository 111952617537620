import React from "react";
import axios from "axios";

import TextField from '../components/UI/Inputs/TextField';
import EmailField from '../components/UI/Inputs/EmailField';
import Checkbox from '../components/UI/Inputs/Checkbox';
import PasswordField from '../components/UI/Inputs/PasswordField';
import Button from "../components/UI/button";
import Main from "components/Layout/main";
import StaticPageMeta from "../components/UI/staticPageMeta";

import "styles/registration-page.scss";


const Registration = (props) => {
    const createCustomer = async (e) => {
        e.preventDefault();

        const options = {
            method: "POST",
            mode: "cors",
            url: "/createCustomer",
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Auth-Token": "5boiq0lbj38dhcx414qcijf1s0v1uvt"
            },
            data: [{
                first_name: "Andrew",
                last_name: "Boyko",
                email: "andrii.boiko@elogic.co",
                password: "string"
            }],
        };

        try {
            const response = await axios(options);
            console.log(response.data); // Handle the response data as needed
        } catch (error) {
            console.error(error); // Handle any errors that occurred during the request
        }
    };

    const gtmData = {
        page: {
            title: "Registration",
            type: "Registration",
        },
    };

    return (
        <>
            <StaticPageMeta pageName="Registration" />
            <Main className="registration-page" gtmData={gtmData}>
                <div className="registration-page__content">
                    <form onSubmit={createCustomer} className='form__input-wrap'>
                        <h1 className="typography__h1">Create new account</h1>
                        <TextField
                            elementId={'user-name'}
                            name={'User name'}
                            placeholder={'Voornaam'}
                            required
                            className={'form__input typography__p--inter'}
                        />
                        <TextField
                            elementId={'last-name'}
                            name={'Last name'}
                            placeholder={'Achternaam'}
                            required
                            className={'form__input typography__p--inter'}
                        />
                        <EmailField
                            elementId={'email'}
                            name={'email'}
                            required
                            placeholder={'Email'}
                            className={'form__input typography__p--inter'}
                        />
                        <PasswordField
                            elementId={'password'}
                            name={'password-field'}
                            required
                            placeholder={'Password'}
                            className={'form__input typography__p--inter'}
                        />
                        <Checkbox
                            elementId={'privacy-policy'}
                            className={'form__checkbox'}
                            name={'Email Newsletter'}
                            required={false}
                            value={
                                'I agree with privacy policy and terms'
                            }
                        />
                        <Button
                            value="Register"
                            type="dark"
                            isArrowShow
                            isSubmit
                        />
                    </form>
                </div>
            </Main>
        </>
    );
};

export default Registration;
