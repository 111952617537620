import * as React from 'react';
import { useState } from 'react';

import Image from "components/UI/image";

import OpenIcon from 'images/svg/password-open-eye.svg';
import CloseIcon from 'images/svg/password-close-eye.svg';

const PasswordField = ({
  name,
  className,
  placeholder,
  elementId,
}) => {

  const [visible, setVisible] = useState(false);

  return (
    <div className='form__password'>
      <input
        id={elementId}
        name={name}
        type={visible ? 'text' : 'password'}
        placeholder={placeholder}
        className={className}
      />
      <button
        className='form__password-icon'
        aria-label='Toggle Password Visibility'
        onClick={() => setVisible((prevState) => !prevState)}
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            setVisible((prevState) => !prevState);
          }
        }}
      >
        {visible ?
            <Image src={OpenIcon}/> :
            <Image src={CloseIcon}/>
        }
      </button>
    </div>
  );
};

export default PasswordField;
